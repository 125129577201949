import * as Popover from "@radix-ui/react-popover";
import {parseDate} from "chrono-node";
import classNames from "classnames";
import {format} from "date-fns";
import {FocusEventHandler, KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {DayPicker, SelectSingleEventHandler} from "react-day-picker";
import Button from "../Button/Button";
import {CalendarIcon} from "../Icons";
import {DateFormat, DateFormatMap, DefaultDateFormat, waitATick} from "../Util";
import "./DateField.scss";

interface DateFieldProps {
  value?: Date;
  onChange?: (date: Date | null, committingChange: boolean) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  dateFormat?: DateFormat;
  focusOnMount?: boolean;
}

const DateField: React.FC<DateFieldProps> = ({
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  dateFormat,
  focusOnMount,
}) => {
  const [inputValue, setInputStringValue] = useState("");
  const dateFormatStr = useMemo(() => DateFormatMap[dateFormat || DefaultDateFormat], [dateFormat]);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>(value);
  const setInputDateValue = useCallback(
    (dateValue: Date | undefined) => setInputStringValue(dateValue ? format(dateValue, dateFormatStr) : ""),
    [dateFormatStr]
  );
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (focusOnMount) window.setTimeout(() => inputRef.current?.focus(), 400);
  }, [focusOnMount]);

  useEffect(() => {
    setInputStringValue(value ? format(value, dateFormatStr) : "");
  }, [dateFormatStr, value]);

  const handleDaySelect: SelectSingleEventHandler = useCallback(
    date => {
      setSelectedDate(date);
      setInputDateValue(date);
      setIsPickerOpen(false);
      if (onChange) onChange(date || null, true);
    },
    [onChange, setInputDateValue]
  );

  const handleInputEdit = useCallback(() => inputRef.current && setInputStringValue(inputRef.current.value), []);

  const handleInputChange = useCallback(() => {
    if (inputRef.current) {
      const date = parseDate(inputRef.current.value) || new Date();
      setSelectedDate(date);
      setInputDateValue(date);
      if (onChange) onChange(date, false);
    }
  }, [onChange, setInputDateValue]);

  const handleInputBlur = useCallback(() => {
    handleInputChange();
    onBlur && onBlur();
    setIsFocused(false);
  }, [handleInputChange, onBlur]);

  const handleKeyPress: KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (event.key === "ArrowDown") {
        setIsPickerOpen(true);
      } else if (event.key === "Enter") {
        handleInputChange();
        if (inputRef.current) {
          const captured = inputRef.current;
          waitATick(() => captured.select());
        }
      }
    },
    [handleInputChange]
  );

  const handleInputFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    event => {
      event.target.select();
      onFocus && onFocus();
      setIsFocused(true);
    },
    [onFocus]
  );

  const handleButtonFocus = useCallback(() => {
    setIsFocused(true);
    onFocus && onFocus();
  }, [onFocus]);

  const handleButtonBlur = useCallback(() => {
    setIsFocused(false);
    onBlur && onBlur();
  }, [onBlur]);

  return (
    <Popover.Root open={isPickerOpen}>
      <div className={classNames("DateField", {Focused: isFocused || isPickerOpen})}>
        <Popover.Trigger className="PopoverTrigger" asChild>
          <input
            ref={inputRef}
            type="text"
            placeholder="Type a date; ↓ to select"
            value={inputValue}
            onChange={handleInputEdit}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onKeyDown={handleKeyPress}
            className={classNames("Input", className)}
            size={9}
          />
        </Popover.Trigger>
        <Button
          type="Borderless"
          icon={CalendarIcon}
          onClick={() => setIsPickerOpen(true)}
          onFocus={handleButtonFocus}
          onBlur={handleButtonBlur}
        />
      </div>
      <Popover.Portal>
        <Popover.Content
          className="PopoverContent DateFieldPopoverContent"
          collisionPadding={16}
          onInteractOutside={() => setIsPickerOpen(false)}
        >
          <DayPicker mode="single" selected={selectedDate} onSelect={handleDaySelect} />
          <Popover.Arrow className="PopoverArrow" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default DateField;

import {ReactNode} from "react";
import "./EditTable.scss";
import classNames from "classnames";
import {removeWhitespace} from "../Util";
import {AnimatePresence} from "framer-motion";

interface EditTableProps {
  cols: string[];
  rows: ReactNode[];
  hasButtons?: boolean;
}

const EditTable = ({cols, rows, hasButtons}: EditTableProps) => {
  return (
    <table className="EditTable">
      <thead>
        <tr>
          {cols.map((col, i) => {
            const colClass = removeWhitespace(col);
            return (
              <th key={colClass} className={classNames("Cell", colClass)}>
                {col}
              </th>
            );
          })}
          {hasButtons && <th className="Cell BtnCol" />}
        </tr>
      </thead>
      <tbody>
        <AnimatePresence>{rows}</AnimatePresence>
      </tbody>
    </table>
  );
};

export default EditTable;

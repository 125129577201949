import {ReactNode} from "react";
import "./Zero.scss";
import {motion} from "framer-motion";

interface ZeroProps {
  image: ReactNode;
  children?: ReactNode;
}

const Zero: React.FC<ZeroProps> = ({children, image}) => {
  return (
    <motion.div layout className="Zero">
      <motion.div layout className="Image">
        {image}
      </motion.div>
      <motion.div layout className="Body">
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Zero;

import classNames from "classnames";
import {ChangeEventHandler, FocusEventHandler, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {waitATick} from "../Util";
import {motion} from "framer-motion";

export type InputType = "password" | "email" | "number" | "text";

interface InputProps {
  type?: InputType;
  value?: string | number | null;
  placeholder?: string | null | false;
  onChange?: (newVal: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  autofocus?: boolean;
  selectOnFocus?: boolean;
  lines?: number;
  prefix?: string; // For currencies and stuff.
  suffix?: string; // For percents and stuff.
}

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  autofocus,
  placeholder,
  lines,
  prefix,
  suffix,
  ...rest
}) => {
  const selectOnFocus = rest.selectOnFocus === undefined ? true : rest.selectOnFocus;
  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e: any) => {
      if (onChange) onChange(e.target.value);
    },
    [onChange]
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const taRef = useRef<HTMLTextAreaElement | null>(null);
  const [includePrefixSuffix, setIncludePrefixSuffix] = useState(true);
  const computedValue = useMemo(
    () => (includePrefixSuffix && prefix ? prefix : "") + value + (includePrefixSuffix && suffix ? suffix : ""),
    [includePrefixSuffix, prefix, suffix, value]
  );

  useEffect(() => {
    if (autofocus) {
      inputRef.current?.focus();
      taRef.current?.focus();
    }
  }, [autofocus]);

  const handleFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    event => {
      setIncludePrefixSuffix(false);
      onFocus && onFocus();
      if (selectOnFocus) waitATick(() => event.target.select());
    },
    [onFocus, selectOnFocus]
  );

  const computedType = useMemo(() => {
    if (type === "password") return "password";
    return includePrefixSuffix ? "text" : type || "text";
  }, [includePrefixSuffix, type]);

  const handleBlur: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(() => {
    setIncludePrefixSuffix(true);
    onBlur && onBlur();
  }, [onBlur]);

  return lines ? (
    <motion.textarea
      layout
      className={classNames("Input", className)}
      rows={lines}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder || undefined}
      ref={taRef}
      value={computedValue}
    />
  ) : (
    <motion.input
      layout
      className={classNames("Input", className)}
      type={computedType}
      step={0.25}
      value={computedValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      placeholder={placeholder || undefined}
      ref={inputRef}
      size={1}
    />
  );
};

export default Input;

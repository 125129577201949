import {format} from "date-fns";
import {useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDataContext} from "../model/DataContextProvider";
import "./InvoiceView.scss";
import {formatAsCurrency, strToDate} from "../Util";
import {useUserContext} from "../model/UserContextProvider";
import {Helmet} from "react-helmet";
import {useLogicContext} from "../model/LogicContextProvider";
import useComputedInvoiceFields from "../model/useComputedInvoiceFields";
import Button from "../Button/Button";
import {BackIcon, PrintIcon} from "../Icons";

interface InvoiceViewProps {
  invoiceId?: string; // Usually supplied by React Router but here for testing
}

const InvoiceView: React.FC<InvoiceViewProps> = props => {
  const {invoiceMap} = useDataContext();
  const {user} = useUserContext();
  const params = useParams();
  const {getComputedSummary, getAdjustedQtyString, getRateString, getTotalAmountForActivity} = useLogicContext();
  const invoiceId = props.invoiceId || params.invoiceId;
  if (!invoiceId) throw new Error("InvoiceView requires an invoice ID.");
  const invoice = useMemo(() => invoiceMap && invoiceMap[invoiceId], [invoiceMap, invoiceId]);
  const {invoiceNumber, client, activitiesForInvoice, total} = useComputedInvoiceFields(invoice);
  const navigate = useNavigate();

  if (!invoice) return <p>Error</p>;

  return (
    <div className="InvoiceView Screen">
      <Helmet>
        <title>Invoice {invoiceNumber}</title>
      </Helmet>
      <div className="Toolbar">
        <div>
          <Button
            icon={BackIcon}
            type="Borderless"
            size="Large"
            onClick={() => navigate("/invoices")}
            tooltip="Back to all invoices"
          />
          <Button
            icon={PrintIcon}
            type="Borderless"
            size="Large"
            onClick={() => window.print()}
            tooltip="Print this invoice"
          />
        </div>
      </div>
      <div className="Content">
        <header>
          <h1>Invoice</h1>
          <div className="InvoiceDate">{format(strToDate(invoice.date), "PPP")}</div>
          <div className="InvoiceNum">{invoiceNumber}</div>
        </header>
        <div className="Parties">
          <div>
            <h3>Bill From:</h3>
            <p>{user?.name}</p>
            <p>{user?.address}</p>
            <p>{user?.email}</p>
          </div>
          <div>
            <h3>Bill To:</h3>
            <p>{client?.name}</p>
            <p>{client?.address}</p>
            <p>{client?.email}</p>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th className="Cell ItemCol">Item</th>
              <th className="Cell QtyCol Right">Qty</th>
              <th className="Cell RateCol">Rate</th>
              <th className="Cell TotalCol">Total (USD)</th>
            </tr>
          </thead>
          <tbody>
            {activitiesForInvoice?.map(act => (
              <tr key={act.id}>
                <td className="Cell ItemCol">{getComputedSummary(act)}</td>
                <td className="Cell QtyCol Right">{getAdjustedQtyString(act)}</td>
                <td className="Cell RateCol">{getRateString(act)}</td>
                <td className="Cell TotalCol">{formatAsCurrency(getTotalAmountForActivity(act))}</td>
              </tr>
            ))}
            <tr>
              <td className="Cell" colSpan={4}>
                &nbsp;
              </td>
            </tr>
            <tr>
              <th colSpan={3} className="Cell Right">
                Subtotal
              </th>
              <td className="Cell TotalCol">{formatAsCurrency(total)}</td>
            </tr>
            <tr>
              <th colSpan={3} className="Cell Right">
                Adjustments
              </th>
              <td className="Cell TotalCol">{formatAsCurrency(0)}</td>
            </tr>
            <tr>
              <th colSpan={3} className="Cell Right">
                Total
              </th>
              <td className="Cell TotalCol Highlight">{formatAsCurrency(total)}</td>
            </tr>
          </tbody>
        </table>
        <div className="Attribution">
          <div>
            <p>Invoice by Barnaby</p>
            <p>
              <a href="https://barnaby.app" target="_barnaby">
                barnaby.app
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceView;

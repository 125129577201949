import "./ClientInput.scss";

import {useCallback, useMemo} from "react";
import classNames from "classnames";
import {useDataContext} from "../model/DataContextProvider";
import Select from "../Select/Select";
import {useLogicContext} from "../model/LogicContextProvider";
import {useUserContext} from "../model/UserContextProvider";

interface ClientInputProps {
  selectedClientId: string | undefined;
  onChange: (clientId: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
}

const ClientInput: React.FC<ClientInputProps> = ({selectedClientId, onChange, className, onBlur, onFocus}) => {
  const {clients} = useDataContext();
  const {setPref} = useUserContext();
  const {defaultClientId} = useLogicContext();
  const client = useMemo(() => {
    if (!selectedClientId) return undefined;
    return clients?.find(el => el.id === selectedClientId);
  }, [selectedClientId, clients]);

  const options = useMemo(() => clients?.map(c => ({value: c.id, label: c.name})), [clients]);

  const handleChange = useCallback(
    (newId: string | undefined) => {
      if (newId && newId !== defaultClientId) setPref("lastClientSelected", newId);
      onChange && onChange(newId || "");
    },
    [defaultClientId, onChange, setPref]
  );

  return (
    <Select
      hideArrow
      className={classNames("ClientInput", className)}
      borderless
      options={options}
      value={client ? {value: client.id, label: client.name} : undefined}
      onChange={handleChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default ClientInput;

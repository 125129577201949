import {useMemo} from "react";
import {Invoice} from "./Model";
import {useDataContext} from "./DataContextProvider";
import {useLogicContext} from "./LogicContextProvider";
import {format} from "date-fns";
import {strToDate} from "../Util";

const useComputedInvoiceFields = (invoice: Invoice | null) => {
  const {clientMap, invoiceActivities} = useDataContext();
  const {getTotalAmountForActivity} = useLogicContext();

  const client = useMemo(() => {
    if (!invoice) return null;
    if (invoice.status !== "Draft") {
      if (!invoice.clientSnapshot)
        throw new Error(`While retrieving client, found invoice ${invoice.id} lacking a client snapshot.`);
      return invoice.clientSnapshot;
    } else {
      return invoice.clientId && clientMap ? clientMap[invoice.clientId] : null;
    }
  }, [clientMap, invoice]);

  const activitiesForInvoice = useMemo(() => {
    return invoiceActivities && invoice ? invoiceActivities[invoice.id] : null;
  }, [invoiceActivities, invoice]);

  const total = useMemo(() => {
    if (!activitiesForInvoice) return 0;
    return activitiesForInvoice.reduce((acc, act) => acc + getTotalAmountForActivity(act), 0);
  }, [activitiesForInvoice, getTotalAmountForActivity]);

  const invoiceNumber = useMemo(() => {
    if (!invoice?.date) return "Unknown";
    return `${format(strToDate(invoice.date), "yyMMdd")}-${invoice.index}`;
  }, [invoice]);

  return {invoiceNumber, client, activitiesForInvoice, total};
};

export default useComputedInvoiceFields;

import classNames from "classnames";
import {useCallback, useState} from "react";
import Input from "../Input/Input";
import {Day, Days, WeekAmounts} from "../model/Model";
import "./WeekInputs.scss";

interface WeekInputsProps {
  value: WeekAmounts;
  onChange: (updatedValue: WeekAmounts) => void;
  onBlur: () => void;
  onFocus: () => void;
}

const WeekInputs: React.FC<WeekInputsProps> = ({value, onChange, onFocus, onBlur}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = useCallback(
    (day: Day, updatedSubvalue: string) => {
      const numVal = updatedSubvalue === "" ? undefined : Number(updatedSubvalue);
      onChange({...value, [day]: numVal});
    },
    [onChange, value]
  );

  const handleFocus = useCallback(() => {
    setIsFocused(true);
    onFocus(); // TODO better unified handling of focus state?
  }, [onFocus]);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    onBlur();
  }, [onBlur]);

  return (
    <div className={classNames("WeekInputs", {Focused: isFocused})}>
      {Days.map(dayStr => {
        const day = dayStr as Day;
        if (day !== "Sat" && day !== "Sun") {
          return (
            <Input
              key={dayStr}
              type="number"
              placeholder={day.substring(0, 2)}
              value={value[day as Day] || ""}
              onChange={v => handleChange(day, v)}
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

export default WeekInputs;

import classNames from "classnames";
import Input, {InputType} from "../Input/Input";
import * as Tooltip from "@radix-ui/react-tooltip";
import "./Field.scss";
import {useMemo} from "react";
import {motion} from "framer-motion";

interface FieldProps {
  label?: string;
  value: string;
  placeholder?: string; // Overridden by inlineLabel
  inlineLabel?: boolean;
  hint?: string;
  type?: InputType;
  lines?: number;
  onChange?: (updatedValue: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  autofocus?: boolean;
  selectOnFocus?: boolean;
  prefix?: string;
  suffix?: string;
  readonly?: boolean;
}

const Field: React.FC<FieldProps> = ({
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  className,
  autofocus,
  inlineLabel,
  lines,
  type,
  placeholder,
  hint,
  prefix,
  suffix,
  readonly,
  ...rest
}) => {
  const core = useMemo(
    () => (
      <motion.div layout className={classNames("Field", className)}>
        {label && !inlineLabel && <label>{label}</label>}
        {readonly && (
          <motion.div layout className="StaticInput">
            {value || placeholder || ""}
          </motion.div>
        )}
        {!readonly && (
          <Input
            type={type}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            autofocus={autofocus}
            selectOnFocus={rest.selectOnFocus === undefined ? true : rest.selectOnFocus}
            placeholder={inlineLabel ? label : placeholder}
            lines={lines}
            prefix={prefix}
            suffix={suffix}
          />
        )}
      </motion.div>
    ),
    [
      autofocus,
      className,
      inlineLabel,
      label,
      lines,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      prefix,
      readonly,
      rest.selectOnFocus,
      suffix,
      type,
      value,
    ]
  );

  return hint ? (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{core}</Tooltip.Trigger>
      <Tooltip.Content side="bottom" className="Tooltip">
        <Tooltip.Arrow className="TooltipArrow" />
        {hint}
      </Tooltip.Content>
    </Tooltip.Root>
  ) : (
    core
  );
};

export default Field;

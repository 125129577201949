import {formatISO, parseISO} from "date-fns";
import {MotionProps} from "framer-motion";
import {v4} from "uuid";
import Joi from "joi";

const $Email = Joi.string().email({tlds: false}).required();

export const IsDevelopment = process.env.REACT_APP_ENV === "development";

export const waitATick = (deferredCode: () => void) => {
  window.setTimeout(deferredCode, 0);
};

export const uuid = v4;

export const validate = (obj: any, schema: Joi.ObjectSchema<any>, returnOnError: boolean = false) => {
  const result = schema.validate(obj, {convert: true});
  if (result.error) {
    if (returnOnError) {
      log(result.error);
      return false;
    }
    throw result.error;
  }

  return obj;
};

export const validateEmail = (val: string) => {
  const result = $Email.validate(val);
  if (result.error) return false;
  return true;
};

export const getValidationError = (obj: any, schema: Joi.ObjectSchema<any>) => {
  const {error} = schema.validate(obj);
  return error;
};

export const OneHour = 1000 * 60 * 60;
export const OneDay = 24 * OneHour;
export const OneWeek = 7 * OneDay;
export const TwoWeeks = 14 * OneDay;

export type DateFormat = "LongDateWithDay" | "ShortDateWithDay" | "ShortDate";
export const DefaultDateFormat: DateFormat = "ShortDate";
export const DateFormatMap: Record<DateFormat, string> = {
  LongDateWithDay: "EEE, PP",
  ShortDateWithDay: "EEE L/dd",
  ShortDate: "L/dd",
};

export const formatAsPercentage = (n: number) => {
  const percentFormatter = new Intl.NumberFormat("en-US", {style: "percent"});
  return percentFormatter.format(n / 100.0);
};

export const formatAsCurrency = (n: number) => {
  let dollarFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  return dollarFormatter.format(n);
};

export const HourLabel = "h";

export const printableTimestemp = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
};

export const log = (...args: any[]) => {
  if (IsDevelopment) console.log(...args);
};

export const ListItemAnimations: MotionProps = {
  initial: {opacity: 0},
  animate: {opacity: 1},
  transition: {duration: 0.4},
  layout: true,
};

export const roundToPrecision = (num: number, precision: number) => {
  return Math.round((num + Number.EPSILON) * Math.pow(10, precision)) / Math.pow(10, precision);
};

export const strToDate = (str: string) => {
  const result = parseISO(str);
  if (isNaN(result as any)) throw new Error(`${str} is not a valid date string.`);
  // log(`Converting ${str} to ${result}`);
  return result;
};

export const dateToStr = (date: Date) => {
  const result = formatISO(date, {representation: "date"});
  // log(`Converting ${date} to ${result}`);
  return result;
};

export const removeWhitespace = (str: string) => str.replace(/\s/g, "");

export const deepCopy = (obj: any) => JSON.parse(JSON.stringify(obj));

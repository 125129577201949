import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Model} from "../model/Model";
import Input, {InputType} from "../Input/Input";
import Select from "../Select/Select";
import Button from "../Button/Button";
import {DeleteIcon} from "../Icons";
import classNames from "classnames";
import {MotionProps} from "framer-motion";
import {waitATick} from "../Util";

export type CellDataType = "text" | "number" | "date" | "currency" | "custom";
export type CellControlType = "input" | "select" | "date";
export type SelectOptions = {label: string; value: any}[];

export type TableRowChangeHandler<T> = (id: string | undefined, updates: Partial<T>) => void;
export type TableRowDeleteHandler = (id: string) => void;

/*
interface ColInfo {
  controlType: CellControlType;
  dataType?: CellDataType;
  placeholder?: string;
  selectOptions?: {label: string; value: any}[];
}
*/
type VisualState = "Highlighted" | "Focused" | null;
type ElementPropsType = Partial<React.HTMLAttributes<HTMLElement> & MotionProps>;

export interface TableRowProps<T> {
  datum: T;
  onChange: TableRowChangeHandler<T>;
  onDelete: TableRowDeleteHandler;
  focusOnMount?: boolean;
}

interface UseTableRowValues<T> {
  current: T;
  setCurrent: (newVal: T) => void;
  handleChange: (field: keyof T, value: any) => void;
  handleImmediateChange: (field: keyof T, value: any) => void;
  handleFocus: () => void;
  handleBlur: () => void;
  makeInput: (key: keyof T, type: CellDataType, value: string, placeholder?: string) => React.ReactNode;
  makeSelect: (key: keyof T, value: string, options: SelectOptions) => React.ReactNode;
  deleteButton: React.ReactNode;
  elementProps: ElementPropsType;
  // controls: Partial<Record<keyof T, React.ReactNode>>;
}

const cellDataTypeToInputType = (dataType: CellDataType): InputType => {
  if (dataType === "number" || dataType === "currency") return "number";
  return "text";
};

const useTableRow = <T extends Model>(
  datum: T,
  onChange: TableRowChangeHandler<T>,
  onDelete: TableRowDeleteHandler,
  className?: string
  // colInfo?: Record<keyof T, ColInfo>
): UseTableRowValues<T> => {
  const [current, setCurrent] = useState(datum);
  useEffect(() => setCurrent(datum), [datum]);
  const queuedUpdates = useRef<Partial<T> | null>(null);
  const blurFocusTimer = useRef<number | null>(null);
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [visualState, setVisualState] = useState<VisualState>(null);
  const ref = useRef<HTMLElement>();

  useEffect(() => {
    //console.log(datum.id.substring(0, 5));
    if (isFocused) setVisualState("Focused");
    else if (isMouseInside) setVisualState("Highlighted");
    else setVisualState(null);
  }, [isFocused, isMouseInside]);

  const clearPendingBlur = useCallback(() => {
    if (blurFocusTimer.current) {
      window.clearTimeout(blurFocusTimer.current);
      blurFocusTimer.current = null;
    }
  }, []);

  const queueValueChange = useCallback((field: keyof T, value: any) => {
    const theseUpdates = {[field]: value} as Partial<T>; // TODO-TS why is explicit cast necessary?
    queuedUpdates.current = {...(queuedUpdates.current || {}), ...theseUpdates};
    setCurrent(old => ({...old, ...theseUpdates}));
  }, []);

  const commitChanges = useCallback(() => {
    if (queuedUpdates.current) {
      onChange(datum.id, queuedUpdates.current);
      queuedUpdates.current = null;
      clearPendingBlur();
    }
  }, [clearPendingBlur, datum.id, onChange]);

  const handleChange = useCallback(
    (field: keyof T, value: any) => {
      queueValueChange(field, value);

      // Select menus sit inside the table row, DOM-wise, but outside its bounds. Selecting from such a menu
      // bypasses browser mouse-leave events, so after the user selects, we erroneously still think the mouse
      // is inside the row. So here, we check after every change whether the mouse is still inside the row.
      if (isMouseInside) {
        waitATick(() => {
          if (!ref.current?.querySelector(":hover")) setIsMouseInside(false);
        });
      }
    },
    [isMouseInside, queueValueChange]
  );

  const handleImmediateChange = useCallback(
    (field: keyof T, value: any) => {
      handleChange(field, value);
      commitChanges();
    },
    [commitChanges, handleChange]
  );

  const handleDelete = useCallback(() => {
    if (!datum.id) throw new Error("Tried to delete a row without an ID.");
    clearPendingBlur();
    onDelete && onDelete(datum.id);
  }, [clearPendingBlur, datum.id, onDelete]);

  const handleFocus = useCallback(() => {
    clearPendingBlur();
    setIsFocused(true);
  }, [clearPendingBlur]);

  const handleBlur = useCallback(() => {
    clearPendingBlur();
    blurFocusTimer.current = window.setTimeout(() => {
      commitChanges();
      setIsFocused(false);
    }, 100);
  }, [clearPendingBlur, commitChanges]);

  const makeInput = useCallback(
    (key: keyof T, type: CellDataType, value: string, placeholder?: string) => (
      <Input
        type={cellDataTypeToInputType(type)}
        value={value}
        onChange={newVal => handleChange(key, newVal)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        prefix={type === "currency" ? "$" : undefined}
        placeholder={placeholder}
      />
    ),
    [handleBlur, handleChange, handleFocus]
  );

  const makeSelect = useCallback(
    (key: keyof T, value: string, options: SelectOptions) => (
      <Select
        options={options}
        value={options.find(opt => opt.value === value)}
        borderless
        hideArrow
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={newVal => handleImmediateChange(key, newVal || "")}
      />
    ),
    [handleBlur, handleFocus, handleImmediateChange]
  );

  const deleteButton = useMemo(
    () => <Button type="Borderless" className="EditTableDeleteButton" icon={DeleteIcon} onClick={handleDelete} />,
    [handleDelete]
  );

  const elementProps: ElementPropsType = useMemo(
    () => ({
      onMouseEnter: () => setIsMouseInside(true),
      onMouseLeave: () => setIsMouseInside(false),
      className: classNames("TableRow", className, visualState),
      initial: {opacity: 0},
      animate: {opacity: 1},
      transition: {duration: 0.4},
      layout: true,
      ref,
    }),
    [className, visualState]
  );

  /*
  const controls = useMemo(() => {
    const result: Partial<Record<keyof T, React.ReactNode>> = {};
    colInfo &&
      Object.entries(colInfo).forEach(([_key, {controlType, dataType, placeholder, selectOptions}]) => {
        console.log(`TODO: Handle dataType ${dataType}`);
        const key = _key as keyof T; // TODO why is this necessary?
        const value = datum[key];
        if (value !== null && typeof value !== "undefined" && typeof value !== "string" && typeof value !== "number") {
          throw new Error("To render a default cell control, value must be standard type.");
        }
        switch (controlType) {
          case "input":
            result[key] = (
              <Input
                value={value ? String(value) : ""}
                onChange={(newVal) => handleChange(key, newVal)}
                onBlur={commitChanges}
                placeholder={placeholder}
              />
            );
            break;

          case "select":
            if (!selectOptions) throw new Error("Select-type cells require selectOptions.");
            result[key] = (
              <Select
                options={selectOptions}
                value={selectOptions.find((opt) => opt.value === value)}
                borderless
                hideArrow
                onChange={(newVal) => handleChange(key, newVal || "", true)}
              />
            );
            break;

          case "date":
            result[key] = (
              <DateField
                dateFormat="ShortDateWithDay"
                value={value ? strToDate(String(value)) : undefined}
                onChange={(newVal) => handleChange(key, newVal || "", true)}
              />
            );
            break;

          default:
            // static
            result[key] = <span>{String(value)}</span>;
            break;
        }
      });

    return result;
  }, [colInfo, commitChanges, datum, handleChange]);*/

  return {
    current,
    setCurrent,
    handleChange,
    handleImmediateChange,
    deleteButton,
    handleFocus,
    handleBlur,
    makeInput,
    makeSelect,
    elementProps,
  };
};

export default useTableRow;

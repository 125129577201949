import "./Select.scss";
import RSSelect, {ClassNamesConfig, GroupBase} from "react-select";
import classNames from "classnames";
import {DropdownIcon} from "../Icons";
import {useCallback, useMemo} from "react";

export type SelectOption<T> = {label: string; value: T};

interface SelectProps<T> {
  hideArrow?: boolean;
  className?: string;
  borderless?: boolean;
  options: SelectOption<T>[] | undefined;
  value: SelectOption<T> | undefined;
  onChange: (value: T | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Select = <T,>({hideArrow, className, borderless, options, value, onChange, onFocus, onBlur}: SelectProps<T>) => {
  const dropdownIndicator = useCallback(() => <div className="Dropdown">{DropdownIcon}</div>, []);

  const rsClassNames: ClassNamesConfig<SelectOption<T>, false, GroupBase<SelectOption<T>>> = useMemo(
    () => ({
      control: ({isFocused}) => (isFocused ? "Control Focused" : "Control"),
      valueContainer: () => "ValueContainer",
      menu: () => "SelectMenu",
      option: state => classNames("Option", {Selected: state.isSelected, Focused: state.isFocused}),
      menuList: () => "List",
      input: () => "InputContainer",
    }),
    []
  );

  return (
    <RSSelect
      components={{DropdownIndicator: dropdownIndicator, IndicatorSeparator: undefined}}
      className={classNames("Select", className, {Borderless: borderless, HideArrow: hideArrow})}
      classNames={rsClassNames}
      openMenuOnFocus
      options={options}
      value={value}
      onChange={selectedOpt => onChange(selectedOpt?.value)}
      onFocus={onFocus}
      onBlur={onBlur}
      tabSelectsValue={false}
    />
  );
};

export default Select;

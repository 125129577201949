import classNames from "classnames";

interface CellProps {
  className?: string;
  children?: React.ReactNode;
}

const Cell: React.FC<CellProps> = ({className, children}) => {
  return <td className={classNames("Cell", className)}>{children}</td>;
};

export default Cell;

import {createContext, ReactNode, useCallback, useContext, useMemo} from "react";
import {useDataContext} from "./DataContextProvider";
import {Activity, ActivityType, Client, Days, DefaultHoursSummary, DefaultPricedSummary} from "./Model";
import {DateFormatMap, formatAsCurrency, formatAsPercentage, roundToPrecision, strToDate} from "../Util";
import {format, startOfWeek} from "date-fns";
import {useUserContext} from "./UserContextProvider";

const calculateAmountWithPercentOverhead = (amount: number, overhead: number | undefined) => {
  const mult = (overhead || 0) / 100.0 + 1;
  return roundToPrecision((amount || 0) * mult, 1);
};

export interface LogicContextValues {
  defaultClientId: string | undefined;
  getUnadjustedQtyByType: (act: Activity) => string;
  getAdjustedQtyString: (act: Activity) => string;
  getComputedSummary: (act: Activity) => string;
  getClientForActivity: (act: Activity) => Client | null;
  getTotalAmountForActivity: (act: Activity) => number;
  getRateString: (act: Activity) => string;
}

export const LogicContext = createContext<LogicContextValues | null>(null);

export const useLogicContext = () => {
  const values = useContext(LogicContext);
  if (!values) throw new Error("Attempted to use LogicContext values outside a context.");
  return values;
};

const LogicContextProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const {clients, invoiceMap} = useDataContext();
  const {getPref} = useUserContext();

  const getClientForActivity = useCallback(
    (act: Activity): Client | null => {
      if (!clients || !invoiceMap) return null;
      if (act.invoiceId) {
        if (!invoiceMap[act.invoiceId]) {
          throw new Error(`Activity ${act.id} references invoice ${act.invoiceId}, which is not in invoice map.`);
        }
        return invoiceMap[act.invoiceId].clientSnapshot as Client; // TODO-TS
      }
      return clients?.find(client => client.id === act.clientId) || null;
    },
    [clients, invoiceMap]
  );

  const totalWeekHoursFromDays = useCallback((act: Activity) => {
    const weeklyHoursByDay = act.weeklyHoursByDay as any; // TODO-TS
    return weeklyHoursByDay ? Days.reduce((acc, day) => (weeklyHoursByDay[day] || 0) + acc, 0) : 0;
  }, []);

  const getUnadjustedQtyByType: (act: Activity) => string = useCallback(
    (act: Activity) => {
      const type = act.type as ActivityType; // TODO-TS
      switch (type) {
        case "Hours":
          return `${act.amount || 0}`;
        case "Priced":
          return `${act.amount || 0}`;
        case "WeekHours":
          return `${totalWeekHoursFromDays(act)}`;
        case "Period":
          return formatAsPercentage(getClientForActivity(act)?.percentAllocation || 1);
      }
    },
    [getClientForActivity, totalWeekHoursFromDays]
  );

  const getTotalAmountForActivity: (act: Activity) => number = useCallback(
    (act: Activity) => {
      const _client = getClientForActivity(act);
      if (!_client) throw new Error(`Tried to total an Activity (${act.id}) without a client.`);

      const _alloc = (_client?.percentAllocation || 100) / 100.0;
      const type = act.type as ActivityType; // TODO-TS
      switch (type) {
        case "Hours":
          return calculateAmountWithPercentOverhead(act.amount, _client.percentOverhead) * _client.hourlyRate;
        case "Priced":
          return act.amount || 0;
        case "WeekHours":
          return (
            calculateAmountWithPercentOverhead(totalWeekHoursFromDays(act), _client.percentOverhead) *
            _client.hourlyRate
          );
        case "Period":
          return _client.periodRate * _alloc;
      }
    },
    [getClientForActivity, totalWeekHoursFromDays]
  );

  const getComputedSummary = useCallback(
    (activity: Activity) => {
      const _client = getClientForActivity(activity);
      switch (activity.type) {
        case "Hours":
          return activity.summary || DefaultHoursSummary;
        case "Priced":
          return activity.summary || DefaultPricedSummary;
        case "WeekHours":
          return `Hours for week of ${format(
            startOfWeek(strToDate(activity.date), {weekStartsOn: 1}),
            DateFormatMap.ShortDate
          )}`;
        case "Period":
          const prefix =
            _client?.period === "Month"
              ? format(strToDate(activity.date), "MMM yyyy")
              : `Week of ${format(startOfWeek(strToDate(activity.date), {weekStartsOn: 1}), DateFormatMap.ShortDate)}`;
          return `${prefix} at ${formatAsPercentage(getClientForActivity(activity)?.percentAllocation || 100)}`;
      }
    },
    [getClientForActivity]
  );

  const getAdjustedQtyByType: (act: Activity) => number = useCallback(
    (act: Activity) => {
      const _client = getClientForActivity(act);
      switch (act.type) {
        case "Hours":
          return calculateAmountWithPercentOverhead(act.amount, _client?.percentOverhead);
        case "Priced":
          return act.amount || 0;
        case "WeekHours":
          return calculateAmountWithPercentOverhead(totalWeekHoursFromDays(act), _client?.percentOverhead);
        case "Period":
          return getClientForActivity(act)?.percentAllocation || 1;
      }
    },
    [getClientForActivity, totalWeekHoursFromDays]
  );

  const getAdjustedQtyString = useCallback(
    (activity: Activity) => {
      switch (activity.type) {
        case "Hours":
        case "WeekHours":
          return `${getAdjustedQtyByType(activity)} h`;
        case "Priced":
          return formatAsCurrency(Number(getAdjustedQtyByType(activity)));
        case "Period":
          return `${getClientForActivity(activity)?.percentAllocation || 100}% of ${
            getClientForActivity(activity)?.period === "Week" ? "week" : "month"
          }`;
        default:
          throw new Error(`Unsupported activity type ${activity.type}`);
      }
    },
    [getAdjustedQtyByType, getClientForActivity]
  );

  const getRateString = useCallback(
    (activity: Activity) => {
      switch (activity.type) {
        case "Priced":
          return "--";
        case "Period":
          return `$${getClientForActivity(activity)?.periodRate || 0}`;
        default:
          return `$${getClientForActivity(activity)?.hourlyRate || 0}`;
      }
    },
    [getClientForActivity]
  );

  const defaultClientId = useMemo<string | undefined>(() => {
    if (!clients?.length) return undefined;
    return (getPref("lastClientSelected") || clients[0].id) as string | undefined;
  }, [clients, getPref]);

  const resultValues: LogicContextValues = {
    defaultClientId,
    getUnadjustedQtyByType,
    getComputedSummary,
    getClientForActivity,
    getTotalAmountForActivity,
    getAdjustedQtyString,
    getRateString,
  };

  return <LogicContext.Provider value={resultValues}>{children}</LogicContext.Provider>;
};

export default LogicContextProvider;

import "./Footer.scss";

const Footer: React.FC<{}> = () => {
  return (
    <footer>
      <div>
        Barnaby © 2023 by{" "}
        <a href="https://dfeldman.co" target="_blank" rel="noreferrer">
          Dave Feldman
        </a>
        . All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;

import {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from "react";
import supabase from "./supabase";
import {Session, User as SbUser} from "@supabase/supabase-js";
import {ActivityType, UserProfile} from "./Model";

export type AuthState = "Unknown" | "Unconfirmed" | "SignedOut" | "SignedIn";

export interface UserPrefs {
  lastClientSelected?: string;
  lastActivityType?: ActivityType;
  nextInvoiceNumber?: number;
}

export interface User {
  email: string;
  name: string | null;
  address: string | null;
  id: string;
}

type AuthAction = (email: string, password: string) => Promise<string | null>;

const performSignOut = async () => {
  const {error} = await supabase.auth.signOut();
  if (error) throw new Error(`Failed to sign out: ${error.message}`);
};

type UserPrefValueType = string | number | Date | null | undefined;
type UserPrefUpdater = (key: keyof UserPrefs, value: UserPrefValueType) => void;
type UserPrefGetter = (key: keyof UserPrefs) => UserPrefValueType;

export interface UserContextValues {
  createUser: AuthAction;
  performSignIn: AuthAction;
  performSignOut: () => Promise<void>;
  getPref: UserPrefGetter;
  setPref: UserPrefUpdater;
  authState: AuthState;
  updateUser: (updates: Partial<User>) => void;
  user: User | null;
}

export const UserContext = createContext<UserContextValues | null>(null);

export const useUserContext = () => {
  const values = useContext(UserContext);
  if (!values) throw new Error("Attempted to use UserContext values outside a context.");
  return values;
};

const UserContextProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const [sbUser, setSbUser] = useState<SbUser | null | undefined>(undefined);
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [session, setSession] = useState<Session | null | undefined>(undefined);
  const [authState, setAuthState] = useState<AuthState>("Unknown");

  // Session tracking
  useEffect(() => {
    supabase.auth.getSession().then(({data: {session: _session}}) => {
      setSession(_session);
    });

    const {
      data: {subscription},
    } = supabase.auth.onAuthStateChange((_event, _session) => {
      // log("Got session on auth change", _session);
      setSession(_session);
    });

    return () => subscription.unsubscribe();
  }, []);

  // User tracking
  useEffect(() => {
    // if (session) {
    (async () => {
      const {
        data: {user: _user},
      } = await supabase.auth.getUser();
      // log("Got user", _user);
      setSbUser(_user || null);
    })();
    // }
  }, [session]);

  // Auth state
  useEffect(() => {
    if (sbUser === undefined || session === undefined) setAuthState("Unknown");
    else if (!sbUser) setAuthState("SignedOut");
    else if (!session) setAuthState("Unconfirmed");
    else setAuthState("SignedIn");
  }, [sbUser, session]);

  // Extended user info and sign-in flag
  useEffect(() => {
    if (session) {
      (async () => {
        const {data, error: selectError} = await supabase.from("userProfiles").select();
        if (selectError) throw new Error(selectError.details);
        if (data.length === 0) {
          // Need to create user profile (TODO-SERVER)

          // TODO Supabase is requiring I supply an ID even though db-wise it's unnecessary
          const {error: insertError} = await supabase.from("userProfiles").insert({id: session.user.id});
          if (insertError) throw new Error(insertError.details);

          const {data: reselectData, error: reselectError} = await supabase.from("userProfiles").select();
          if (reselectError) throw new Error(reselectError.details);

          if (reselectData.length !== 1) {
            throw new Error(`Expected one record back after creating user profile; got ${data.length}`);
          }

          setProfile(reselectData[0]);
        } else {
          if (data.length !== 1) throw new Error(`User profile request expected one response; got ${data.length}`);
          setProfile(data[0]);
        }
      })();
    }
  }, [session]);

  // Synthesized user
  const user: User | null = useMemo(() => {
    if (!sbUser || !profile) return null;
    if (!sbUser.email) throw new Error(`User ${sbUser.id} lacks an email.`);
    const result: User = {
      name: profile.name,
      address: profile.address,
      email: sbUser.email,
      id: sbUser.id,
    };
    return result;
  }, [sbUser, profile]);

  // Add internal analytics flag if needed
  useEffect(() => {
    if ((window as any)?.heap && profile?.isInternal) {
      (window as any).heap.addUserProperties({Internal: true});
      (window as any).heap.addEventProperties({Internal: true});
    }
  }, [profile]);

  const createUser: AuthAction = useCallback(async (email, password) => {
    // Create Supabase user
    const {
      error: signUpError,
      data: {user: newUser, session: receivedSession},
    } = await supabase.auth.signUp({email, password});

    // log("sign-up error", signUpError);
    // log("new user object", newUser);
    // log("session", session);

    if (signUpError) {
      console.error("Sign-up error:", signUpError);
      setAuthState("SignedOut");
      return signUpError.message;
    }
    if (!newUser) {
      setAuthState("SignedOut");
      return "Sign-up succeeded but no user returned.";
    }

    // If we have user but no session, we're awaiting email confirmation
    if (!receivedSession) {
      setAuthState("Unconfirmed");
      return null;
    }

    setAuthState("SignedIn");
    return null;
  }, []);

  const performSignIn: AuthAction = useCallback(async (email, password) => {
    const {error} = await supabase.auth.signInWithPassword({email, password});

    if (error) return error.message;

    // log("user", user);
    // log("session", session);

    return null;
  }, []);

  const updateUser = useCallback(
    (updates: Partial<User>) => {
      (async () => {
        if (!sbUser) throw new Error("Tried to update a user when we aren't signed in.");
        if (updates.email) throw new Error("Can't currently update email address");
        const {error} = await supabase.from("userProfiles").update(updates).eq("id", sbUser.id);
        if (error) throw new Error(error.details);

        setProfile(old => {
          if (!old) throw new Error("While updating profile, couldn't find profile.");
          return {...old, ...updates};
        });
      })();
    },
    [sbUser]
  );

  const setPref: UserPrefUpdater = useCallback(
    (key, val) => {
      (async () => {
        if (!profile) throw new Error("Tried to update user pref when we don't have a profile.");
        if (!sbUser) throw new Error("Tried to update user pref when we don't have a user.");

        const prefs = profile.prefs as UserPrefs;
        const newPrefs = {...prefs, [key]: val};
        const {error} = await supabase.from("userProfiles").update({prefs: newPrefs}).eq("id", sbUser.id);
        if (error) throw new Error(error.details);

        // TODO safer but slower if we grab updated prefs from the db
        setProfile(old => (old ? {...old, prefs: newPrefs} : null));
      })();
    },
    [profile, sbUser]
  );

  const getPref: UserPrefGetter = useCallback(
    key => {
      if (!profile || !sbUser) return undefined;
      const prefs = profile.prefs as UserPrefs;
      return prefs[key];
    },
    [profile, sbUser]
  );

  const resultValues: UserContextValues = {
    setPref,
    getPref,
    createUser,
    performSignIn,
    performSignOut,
    authState,
    user,
    updateUser,
  };

  return <UserContext.Provider value={resultValues}>{children}</UserContext.Provider>;
};

export default UserContextProvider;

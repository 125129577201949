import Joi from "joi";
import {dateToStr} from "../Util";
import {Database} from "./supabase.types";

export interface Model {
  id: string;
  date: string;
  ownerId: string | null;
}
export type UnsavedModel<T extends Model> = Partial<Omit<T, "id" | "ownerId">>;
export type ModelUpdates<T extends Model> = UnsavedModel<T>;

export const $Model = Joi.object({
  id: Joi.string().required(),
  date: Joi.date().required(),
});

type Tables = Database["public"]["Tables"];

export type UserProfile = Tables["userProfiles"]["Row"];

const LocalModelBase: Model = {
  id: "",
  date: "",
  ownerId: null,
};

// -------------------------------------------------------------------------------------------------
//                                       ACTIVITY
// -------------------------------------------------------------------------------------------------

export type Activity = Omit<Tables["activities"]["Row"], "type" | "weeklyHoursByDay" | "createdAt"> & {
  type: ActivityType;
  weeklyHoursByDay: WeekAmounts;
};
export type ActivityType = "Priced" | "Hours" | "Period" | "WeekHours";
export const ActivityTypeLabels: Record<ActivityType, string> = {
  Priced: "Priced",
  Hours: "Hourly",
  Period: "Time Period",
  WeekHours: "Daily Hours",
};
export const ActivityTypeOptionArray = Object.keys(ActivityTypeLabels).map(k => ({
  value: k,
  label: ActivityTypeLabels[k as ActivityType],
}));

export type Day = "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "Sun";
export type WeekAmounts = Record<Day, number | null>;
export const EmptyWeekAmounts: WeekAmounts = {
  Mon: null,
  Tue: null,
  Wed: null,
  Thu: null,
  Fri: null,
  Sat: null,
  Sun: null,
};
export const Days = Object.keys(EmptyWeekAmounts);

export const DefaultHoursSummary = "Hourly work";
export const DefaultPricedSummary = "Priced project work";
export const DefaultActivityType: ActivityType = "Hours";

export const LocalActivityBase: Activity = {
  ...LocalModelBase,
  amount: 0,
  clientId: null,
  invoiceId: null,
  summary: null,
  type: DefaultActivityType,
  weeklyHoursByDay: EmptyWeekAmounts,
};

// -------------------------------------------------------------------------------------------------
//                                       INVOICE
// -------------------------------------------------------------------------------------------------

export type InvoiceStatus = "Draft" | "Sent" | "Paid";
export type Invoice = Omit<Tables["invoices"]["Row"], "status" | "clientSnapshot" | "createdAt"> & {
  status: InvoiceStatus;
  clientSnapshot: Client | null;
};
export const InvoiceStatusSelectOptions: {value: InvoiceStatus; label: string}[] = [
  {label: "Draft", value: "Draft"},
  {label: "Sent", value: "Sent"},
  {label: "Paid", value: "Paid"},
];
export const LocalInvoiceBase: Invoice = {
  ...LocalModelBase,
  clientId: null,
  clientSnapshot: null,
  index: 0,
  status: "Draft",
};

// -------------------------------------------------------------------------------------------------
//                                       CLIENT
// -------------------------------------------------------------------------------------------------

export type Client = Omit<Tables["clients"]["Row"], "defaultActivityType" | "period" | "createdAt"> & {
  defaultActivityType: ActivityType;
  period: Period;
};
export type Period = "Week" | "Month";

export const NewUnsavedClient: UnsavedModel<Client> = {
  name: "",
  date: dateToStr(new Date()),
  period: "Week",
  hourlyRate: 100,
  periodRate: 500,
  percentOverhead: 0,
  percentAllocation: 100,
};

// TODO reimplement–
export const $Client = $Model
  .append({
    name: Joi.string().required(),
    address: Joi.string().allow(null, ""),
    email: Joi.string().email({tlds: false}).allow(null, ""),
    period: Joi.string().required(),
    hourlyRate: Joi.number().min(1),
    periodRate: Joi.number().min(1),
    percentAllocation: Joi.number().integer().min(1).max(100),
    percentOverhead: Joi.number().integer().min(0).max(100),
  })
  .unknown(true);

export const LocalClientBase: Client = {
  ...LocalModelBase,
  address: null,
  name: "New Client",
  defaultActivityType: DefaultActivityType,
  email: null,
  hourlyRate: 100,
  percentAllocation: 100,
  percentOverhead: 0,
  period: "Week",
  periodRate: 500,
};

// -------------------------------------------------------------------------------------------------
//                                       Collections
// -------------------------------------------------------------------------------------------------

export type ModelCreator<T extends Model> = (obj: UnsavedModel<T>) => string;
export type ModelUpdater<T extends Model> = (id: string, updates: ModelUpdates<T>) => void;
export type ModelDeleter = (id: string) => void;

import ClientInput from "../ClientInput/ClientInput";
import DateField from "../DateField/DateField";
import {Invoice, InvoiceStatusSelectOptions} from "../model/Model";
import "./InvoiceRow.scss";
import {Link, useNavigate} from "react-router-dom";
import {dateToStr, formatAsCurrency, strToDate} from "../Util";
import useComputedInvoiceFields from "../model/useComputedInvoiceFields";
import useTableRow, {TableRowProps} from "../Table/useTableRow";
import Cell from "../Table/Cell";
import classNames from "classnames";
import {motion} from "framer-motion";
import {DrilldownIcon} from "../Icons";
import Button from "../Button/Button";

const InvoiceRow: React.FC<TableRowProps<Invoice>> = ({datum, onChange, onDelete, focusOnMount}) => {
  const {activitiesForInvoice, total, invoiceNumber} = useComputedInvoiceFields(datum);
  const {
    handleImmediateChange,
    handleChange,
    handleFocus,
    handleBlur,
    deleteButton,
    makeSelect,
    current,
    elementProps,
  } = useTableRow(datum, onChange, onDelete, classNames(datum.status, "InvoiceRow"));
  const navigate = useNavigate();

  return (
    <motion.tr {...elementProps}>
      <Cell>
        <DateField
          value={strToDate(current.date)}
          onChange={(newDate, committingChange) =>
            (committingChange ? handleImmediateChange : handleChange)("date", newDate ? dateToStr(newDate) : null)
          }
          onFocus={handleFocus}
          onBlur={handleBlur}
          dateFormat="ShortDateWithDay"
          focusOnMount={focusOnMount}
        />
      </Cell>
      <Cell>
        <Link to={`/invoices/${datum.id}`}>{invoiceNumber}</Link>
      </Cell>
      <Cell>
        <ClientInput
          selectedClientId={current.clientId || undefined}
          onChange={newId => handleImmediateChange("clientId", newId)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Cell>
      <Cell className="Items">
        <span>{activitiesForInvoice?.length}</span>
      </Cell>
      <Cell className="Amount">
        <span>{formatAsCurrency(total)}</span>
      </Cell>
      <Cell className="Status">{makeSelect("status", current.status, InvoiceStatusSelectOptions)}</Cell>
      <Cell className="BtnCol">{deleteButton}</Cell>
      <Cell className="BtnCol Drilldown">
        <Button
          type="Borderless"
          icon={DrilldownIcon}
          className="DrilldownButton"
          onClick={() => {
            navigate(`/invoices/${datum.id}`);
          }}
        />
      </Cell>
    </motion.tr>
  );
};

export default InvoiceRow;

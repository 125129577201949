import ActivityList from "../ActivityList/ActivityList";
import "./App.scss";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import ClientList from "../ClientList/ClientList";
import InvoiceList from "../InvoiceList/InvoiceList";
import InvoiceView from "../InvoiceView/InvoiceView";
import Login from "../Login/Login";
import {useUserContext} from "../model/UserContextProvider";
import ProfileView from "../ProfileView/ProfileView";
import Nav from "./Nav";
import * as Tooltip from "@radix-ui/react-tooltip";
import {useDataContext} from "../model/DataContextProvider";
import {useMemo} from "react";
import Footer from "./Footer";
import CsvInvoiceList from "../InvoiceList/CsvInvoiceList";

const App: React.FC<{}> = () => {
  const {authState} = useUserContext();
  const {clients} = useDataContext();
  const defaultNavItem = useMemo(() => {
    if (!clients) return null;
    if (clients.length === 0) return "clients";
    return "activities";
  }, [clients]);

  // TODO does this need to update at any point other than on component load?
  const {search} = useLocation();
  const csv = useMemo(() => {
    const params = new URLSearchParams(search);
    return params.get("format") === "csv";
  }, [search]);

  /*
  TODO Global error handler. Works, kinda, in Chrome but not Safari. In Chrome it produces multiple of the same error.

  useEffect(() => {
    console.log("registering handlers");
    window.addEventListener("error", (e) => {
      alert(e.message);
      e.preventDefault();
    });
  }, []);
  */
  const core = useMemo(() => {
    if (authState === "Unknown" || (authState === "SignedIn" && clients === null)) {
      // Pending authentication OR signed-in but waiting on data
      // TODO really only need the latter condition to calculate defaultNavItem...maybe a better way that
      // doesn't block loads when no redirect is necessary?
      return (
        <Routes>
          <Route
            path="*"
            element={
              <div className="App Loading">
                <Nav />
                <main>
                  <div>
                    <div>Loading...</div>
                  </div>
                  <Footer />
                </main>
              </div>
            }
          />
        </Routes>
      );
    }

    return (
      <div className="App">
        {authState === "SignedIn" ? (
          <>
            <Nav />
            <main>
              <Routes>
                <Route path="/" element={<Navigate to={`/${defaultNavItem}`} replace />} />
                <Route path="/login" element={<Navigate to={`/${defaultNavItem}`} replace />} />
                <Route path="/activities" element={<ActivityList />} />
                <Route path="/invoices/:invoiceId" element={<InvoiceView />} />
                <Route path="/invoices" element={csv ? <CsvInvoiceList /> : <InvoiceList />} />
                <Route path="/clients" element={<ClientList />} />
                <Route path="/profile" element={<ProfileView />} />
                <Route path="*" element={<>404 page goes here</>} />
              </Routes>
              <Footer />
            </main>
          </>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        )}
      </div>
    );
  }, [authState, clients, csv, defaultNavItem]);

  return <Tooltip.Provider delayDuration={300}>{core}</Tooltip.Provider>;
};

export default App;

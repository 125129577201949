import classNames from "classnames";
import "./Button.scss";
import {useMemo} from "react";
import * as Tooltip from "@radix-ui/react-tooltip";

export type ButtonType = "Primary" | "Borderless" | "Default";
export type ButtonSize = "Medium" | "Large";

interface ButtonProps {
  type?: ButtonType;
  size?: ButtonSize;
  label?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  role?: "submit" | "button";
  disabled?: boolean;
  tooltip?: string;
}

const Button: React.FC<ButtonProps> = ({
  tooltip,
  type,
  label,
  icon,
  onClick,
  className,
  role,
  disabled,
  onFocus,
  onBlur,
  size,
}) => {
  const core = useMemo(() => {
    return (
      <button
        className={classNames("Button", type, size, className)}
        type={role || "button"}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      >
        {icon}
        {label && <span>{label}</span>}
      </button>
    );
  }, [className, disabled, icon, label, onBlur, onClick, onFocus, role, size, type]);

  return tooltip ? (
    <Tooltip.Root>
      <Tooltip.Trigger asChild>{core}</Tooltip.Trigger>
      <Tooltip.Content side="bottom" className="Tooltip" collisionPadding={24}>
        <Tooltip.Arrow className="TooltipArrow" />
        {tooltip}
      </Tooltip.Content>
    </Tooltip.Root>
  ) : (
    core
  );
};

export default Button;

import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Model, ModelCreator, ModelDeleter, ModelUpdater} from "./Model";
import {dateToStr, deepCopy, printableTimestemp as printableTimestamp, uuid} from "../Util";
import supabase from "./supabase";
import {RealtimeChannel} from "@supabase/supabase-js";
import {useUserContext} from "./UserContextProvider";

export interface DbCollection<T extends Model> {
  readonly all: T[] | null;
  readonly map: Record<string, T> | null;
  create: ModelCreator<T>;
  update: ModelUpdater<T>;
  del: ModelDeleter;
}

const sortCollectionByDate = <T extends Model>(coll: T[]) => {
  return coll.sort((a, b) => {
    if (a.date === b.date) return 0;
    return b.date < a.date ? 1 : -1;
  });
};

const useDbCollection = <T extends Model>(
  collectionName: string,
  completeModelBase: T,
  ready: boolean
): DbCollection<T> => {
  const {user} = useUserContext();
  const [serverAll, setServerAll] = useState<T[] | null>(null);
  const [unsaved, setUnsaved] = useState<T[]>([]);
  const [all, setAll] = useState<T[] | null>(null);
  const channel = useRef<RealtimeChannel | null>(null);

  // When a channel closes on us, I think we want to remove it? Not sure that's actually necessary. Anyway,
  // removing a closed channel still seems to generate a Closed event, so I've created this flag to let us
  // know when to ignore such events.
  const shouldReopenChannelOnClose = useRef(true);

  useEffect(() => {
    setAll(serverAll ? [...serverAll, ...unsaved] : null);
  }, [serverAll, unsaved]);

  const getUnsaved = useCallback((id: string) => unsaved.find(el => el.id === id), [unsaved]);

  const fetchAndSubscribe = useCallback(async () => {
    const _log = (msg: string, ...additions: any[]) => {
      console.log(`${printableTimestamp()} - ${collectionName} - ${msg}`, ...(additions || []));
    };

    if (channel.current) {
      // TODO I'm not sure we need to remove a channel once it's closed--may get removed automatically.
      shouldReopenChannelOnClose.current = false;
      await supabase.removeChannel(channel.current);
      channel.current = null;
    }

    const {data, error} = await supabase
      .from(collectionName)
      .select()
      .order("date", {ascending: true})
      .order("createdAt", {ascending: true});
    if (error) throw new Error(error.message);
    setServerAll(data);

    channel.current = supabase
      .channel(`${collectionName}_listener`)
      .on("postgres_changes", {event: "*", schema: "public", table: collectionName}, payload => {
        console.log(`Received ${payload.eventType} event`);
        setServerAll(old => {
          if (!old) throw new Error("Received db-change event prior to receiving initial data");
          switch (payload.eventType) {
            case "INSERT":
              setUnsaved(old => old.filter(item => item.id !== payload.new.id));
              return sortCollectionByDate([...old, payload.new as T]); // TODO Optimize performance? Better typing?
            case "UPDATE": {
              setUnsaved(old =>
                old.filter(item => {
                  if (item.id === payload.new.id) {
                    console.warn(`Received an update event for unsaved item ${item.id}`);
                    return false;
                  }
                  return true;
                })
              );
              const idx = old.findIndex(item => item.id === payload.new.id);
              if (idx < 0) {
                console.error("Received an update event with no preeixsting matching ID");
                return old;
              }
              const shouldSort = payload.new.date !== old[idx].date;
              old[idx] = payload.new as T; // TODO can this be better typed?
              const result = [...old];
              return shouldSort ? sortCollectionByDate(result) : result; // TODO optimize performance?
            }
            case "DELETE": {
              const idx = old.findIndex(item => item.id === payload.old.id);
              if (idx < 0) {
                console.error("Received a delete event with no preexisting matching ID");
                return old;
              }
              old.splice(idx, 1);
              return [...old];
            }
            default:
              console.warn("Received unhandled db-change event.");
              console.log(payload);
              return old;
          }
        });
      })
      .subscribe((status, err) => {
        _log(`Received ${status}${err ? `- ${err}` : ""}`);

        if (status === "CLOSED") {
          if (shouldReopenChannelOnClose.current) {
            _log(`Channel closed. Reopening.`);
            fetchAndSubscribe();
          } else {
            shouldReopenChannelOnClose.current = true;
          }
        }
      });
  }, [collectionName]);

  useEffect(() => {
    if (ready) fetchAndSubscribe();
  }, [ready, fetchAndSubscribe]);

  const map = useMemo(() => {
    if (!all) return null;
    const _map: Record<string, T> = {};
    all.forEach(obj => (_map[obj.id] = obj));
    return _map;
  }, [all]);

  const createOnServer = useCallback(
    async (obj: T) => {
      if (!ready) throw new Error("Need to sign in first.");
      const {error} = await supabase.from(collectionName).insert(obj);
      if (error) return error.message;
      return null;
    },
    [collectionName, ready]
  );

  const create: ModelCreator<T> = useCallback(
    values => {
      const assembledValues: T = {
        ...deepCopy(completeModelBase),
        date: dateToStr(new Date()),
        ...values,
        id: uuid(),
        ownerId: user ? user.id : null,
      };
      setUnsaved(old => [...old, assembledValues]);
      return assembledValues.id;
    },
    [completeModelBase, user]
  );

  const update: ModelUpdater<T> = useCallback(
    async (id, updates) => {
      if (!ready) throw new Error("Need to sign in first.");
      const unsavedT = getUnsaved(id);
      if (unsavedT) {
        const createError = await createOnServer({...unsavedT, ...updates});
        if (createError) {
          console.error(createError); // TODO Toast
          setUnsaved([]);
        } // Note lack of else clause: we don't remove this from unsaved until we get the inserted item back from server
      } else {
        const {error: updateError} = await supabase.from(collectionName).update(updates).eq("id", id);
        if (updateError) {
          console.error(updateError); // TODO Toast
        }
      }
    },
    [collectionName, createOnServer, getUnsaved, ready]
  );

  const del: ModelDeleter = useCallback(
    async id => {
      if (!ready) throw new Error("Need to sign in first.");
      const unsavedT = getUnsaved(id);
      if (unsavedT) {
        setUnsaved(old => old.filter(el => el.id !== id));
      } else {
        const {error} = await supabase.from(collectionName).delete().eq("id", id);
        if (error) console.error(error.message); // TODO Toast
      }
    },
    [collectionName, getUnsaved, ready]
  );

  return {all, map, create, update, del};
};

export default useDbCollection;

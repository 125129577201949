import classNames from "classnames";
import {useCallback, useMemo, useRef} from "react";
import Button from "../Button/Button";
import ClientView from "../ClientView/ClientView";
import {Client} from "../model/Model";
import {useDataContext} from "../model/DataContextProvider";
import "./ClientList.scss";
import {AnimatePresence} from "framer-motion";
import {Helmet} from "react-helmet";
import {TableRowChangeHandler, TableRowDeleteHandler} from "../Table/useTableRow";
import {ReactComponent as ZeroImage} from "../images/zero-clients.svg";
import Zero from "../Zero/Zero";

const ClientList: React.FC = () => {
  const {updateClient, createClient, deleteClient, clients: _clients} = useDataContext();
  const justAdded = useRef<string | null>(null);
  const clients = useMemo(() => (_clients ? [..._clients].reverse() : []), [_clients]);

  const handleClientChange: TableRowChangeHandler<Client> = useCallback(
    (clientId, updates) => {
      if (!clientId) throw new Error("ClientList tried to update a Client without an ID.");
      updateClient(clientId, updates);
    },
    [updateClient]
  );

  const handleAdd = useCallback(async () => {
    justAdded.current = createClient({});
  }, [createClient]);

  const handleDelete: TableRowDeleteHandler = useCallback(
    id => {
      deleteClient(id);
    },
    [deleteClient]
  );

  return (
    <div className="ClientList Screen">
      <Helmet>
        <title>Clients | Barnaby</title>
      </Helmet>
      <header>
        <h1>Clients</h1>
        <Button onClick={handleAdd} label="Add Client" />
      </header>
      {clients.length ? (
        <div className={classNames("Content")}>
          <AnimatePresence>
            {clients?.map(client => (
              <ClientView
                key={client.id}
                datum={client}
                onChange={handleClientChange}
                onDelete={handleDelete}
                focusOnMount={client.id === justAdded.current}
              />
            ))}
          </AnimatePresence>
        </div>
      ) : (
        <Zero image={<ZeroImage />}>
          Welcome to Barnaby! <strong>Click Add, above right, to add your first client</strong>. We'll use their name,
          address, and billing info to generate invoices with a minimum of fuss.
        </Zero>
      )}
    </div>
  );
};

export default ClientList;

import {
  HiRocketLaunch,
  HiBriefcase,
  HiDocumentText,
  HiUserCircle,
  HiChevronDown,
  HiChatBubbleBottomCenter,
  HiOutlinePrinter,
} from "react-icons/hi2";
import {HiArrowRight, HiArrowLeft, HiLogout} from "react-icons/hi";
import {FiCalendar, FiTrash2} from "react-icons/fi";

export const ActivityIcon = <HiRocketLaunch />;
export const ClientIcon = <HiBriefcase />;
export const InvoiceIcon = <HiDocumentText />;
export const ProfileIcon = <HiUserCircle />;
export const SignOutIcon = <HiLogout />;
export const CalendarIcon = <FiCalendar />;
export const DeleteIcon = <FiTrash2 />;
export const DropdownIcon = <HiChevronDown />;
export const DrilldownIcon = <HiArrowRight />;
export const BackIcon = <HiArrowLeft />;
export const FeedbackIcon = <HiChatBubbleBottomCenter />;
export const PrintIcon = <HiOutlinePrinter />;

import {useMemo} from "react";
import {useDataContext} from "../model/DataContextProvider";
import "./InvoiceList.scss";
import CsvInvoiceRow from "../InvoiceRow/CsvInvoiceRow";

const CsvInvoiceList: React.FC = () => {
  const {invoices: _invoices} = useDataContext();
  const invoices = useMemo(() => (_invoices ? [..._invoices].reverse() : []), [_invoices]);

  return (
    <code className="InvoiceList Screen">
      "Date", "Number", "Client", "Amount", "Status", "Link"
      <br />
      {invoices?.map(inv => (
        <CsvInvoiceRow key={inv.id} datum={inv} />
      ))}
    </code>
  );
};

export default CsvInvoiceList;

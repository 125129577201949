import {Helmet} from "react-helmet";
import Field from "../Field/Field";
import {User, useUserContext} from "../model/UserContextProvider";
import "./ProfileView.scss";
import {useCallback, useEffect, useState} from "react";

const ProfileView: React.FC = () => {
  const {user, updateUser} = useUserContext();
  const [current, setCurrent] = useState<User | null>(null);
  const [queuedChanges, setQueuedChanges] = useState<Partial<User> | null>(null);

  useEffect(() => {
    setCurrent(user);
    setQueuedChanges(null);
  }, [user]);

  const handleUpdate = useCallback((changes: Partial<User>) => {
    setCurrent(old => {
      if (!old) throw new Error("No current user");
      return {...old, ...changes};
    });
    setQueuedChanges(old => ({...old, ...changes}));
  }, []);

  const commitChanges = useCallback(() => {
    if (queuedChanges) {
      updateUser(queuedChanges);
      setQueuedChanges(null);
    }
  }, [queuedChanges, updateUser]);

  if (!user) return <>Loading...</>;

  return (
    <div className="ProfileView Screen">
      <Helmet>
        <title>Profile | Barnaby</title>
      </Helmet>
      <header>
        <h1>Profile</h1>
      </header>
      <main className="SimpleForm">
        <Field
          label="Name"
          value={current?.name || ""}
          onChange={val => handleUpdate({name: val})}
          onBlur={commitChanges}
        />
        <Field
          label="Address"
          value={current?.address || ""}
          onChange={val => handleUpdate({address: val})}
          selectOnFocus={false}
          onBlur={commitChanges}
          lines={2}
        />
        <Field label="Email" value={user.email} readonly />
      </main>
    </div>
  );
};

export default ProfileView;

import {useCallback, useMemo, useRef} from "react";
import ActivityRow from "./ActivityRow";
import Button from "../Button/Button";
import {useDataContext} from "../model/DataContextProvider";
import "./ActivityList.scss";
import {Helmet} from "react-helmet";
import {useLogicContext} from "../model/LogicContextProvider";
import {Activity, DefaultActivityType} from "../model/Model";
import EditTable from "../Table/EditTable";
import {TableRowChangeHandler, TableRowDeleteHandler} from "../Table/useTableRow";
import Zero from "../Zero/Zero";
import {ReactComponent as ZeroImage} from "../images/zero-activities.svg";
import {Link} from "react-router-dom";

const ActivityList: React.FC = () => {
  const {
    activities: _activities,
    createActivity,
    updateActivity,
    deleteActivity,
    clientMap,
    clients,
  } = useDataContext();
  const {defaultClientId} = useLogicContext();
  const activities = useMemo(() => (_activities ? [..._activities].reverse() : []), [_activities]);
  const justAdded = useRef<string | null>(null);

  const addActivity = useCallback(() => {
    justAdded.current = createActivity({
      type:
        defaultClientId && clientMap
          ? clientMap[defaultClientId]?.defaultActivityType || DefaultActivityType
          : DefaultActivityType,
      clientId: defaultClientId,
    });
  }, [clientMap, createActivity, defaultClientId]);

  const handleChange: TableRowChangeHandler<Activity> = useCallback(
    (id, updates) => {
      if (!activities) throw new Error("Attempted to save an activity when activities is not set.");
      if (!id) throw new Error("Attempted to save an activity but missing its ID.");
      updateActivity(id, updates);
    },
    [activities, updateActivity]
  );

  const handleDelete: TableRowDeleteHandler = useCallback(
    id => {
      if (!activities) throw new Error("Attempted to delete an activity when activities is not set.");
      if (!id) throw new Error("Attempted to delete an activity but missing its ID.");
      deleteActivity(id);
    },
    [activities, deleteActivity]
  );

  const [zeroContent, disabledAddTooltip] = useMemo(() => {
    if (activities?.length) return [undefined, undefined];
    else if (clients?.length) {
      return [
        <>
          Use Activities to track the work you’ll eventually invoice. Barnaby offers several types of activity depending
          on how you work—from simple hourly and priced tasks to our convenient Daily Hours. To create your first
          activity, <strong>click the Add button, above right.</strong>
        </>,
        undefined,
      ];
    } else {
      return [
        <>
          Activities rely on Clients for hourly rates and billing details. To get started,{" "}
          <strong>
            <Link to="/clients">head over to the Clients screen!</Link>
          </strong>
        </>,
        "Before adding activities, visit the Clients screen and add your first client.",
      ];
    }
  }, [activities?.length, clients?.length]);

  return (
    <div className="ActivityList Screen">
      <Helmet>
        <title>Activities | Barnaby</title>
      </Helmet>
      <header>
        <h1>Activities</h1>
        <Button
          disabled={Boolean(disabledAddTooltip)}
          tooltip={disabledAddTooltip}
          onClick={addActivity}
          label="Add Activity"
        />
      </header>
      {activities?.length ? (
        <EditTable
          hasButtons
          cols={["Date", "Client", "Type", "Details", "Total"]}
          rows={activities?.map(act => (
            <ActivityRow
              key={act.id || "new"}
              datum={act}
              onChange={handleChange}
              onDelete={handleDelete}
              focusOnMount={act.id === justAdded.current}
            />
          ))}
        />
      ) : (
        <Zero image={<ZeroImage />}>{zeroContent}</Zero>
      )}
    </div>
  );
};

export default ActivityList;

import * as Tabs from "@radix-ui/react-tabs";
import {FormEventHandler, useCallback, useMemo, useState} from "react";
import Button from "../Button/Button";
import Field from "../Field/Field";
import {validateEmail} from "../Util";
import "./Login.scss";
import {useUserContext} from "../model/UserContextProvider";
import {AnimatePresence, motion} from "framer-motion";
import {ReactComponent as Logo} from "../images/logo.svg";
import Footer from "../App/Footer";

const Login: React.FC = () => {
  const {performSignIn, createUser, authState} = useUserContext();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passConfirm, setPassConfirm] = useState("");
  const [tab, setTab] = useState("sign-up");
  const isSignUp = useMemo(() => tab === "sign-up", [tab]);
  const [canShowError, setCanShowError] = useState(false);
  const [serverError, setServerError] = useState<string | null>(null);

  const error = useMemo(() => {
    if (email && !validateEmail(email)) return "The email you entered is not a valid email address.";
    if (password.length && password.length < 8)
      return "Please enter a password of at least 8 characters. No, there are no other requirements.";
    if (isSignUp && passConfirm && passConfirm !== password) return "Password and confirmation don't match.";
    return serverError;
    // if (!(email && password && (passConfirm || !isSignUp))) return "Please fill out all fields.";
  }, [email, isSignUp, passConfirm, password, serverError]);

  const changeHandler = useCallback((setter: (val: string) => void) => {
    return (val: string) => {
      setter(val);
      setCanShowError(false);
      setServerError(null);
    };
  }, []);

  const handleBlur = useCallback(() => setCanShowError(true), []);

  const handleSubmit: FormEventHandler = useCallback(
    event => {
      if (!error) {
        (isSignUp ? createUser : performSignIn)(email, password).then(err => {
          if (err) setServerError(err);
        });
      }
      event.preventDefault();
      return false;
    },
    [createUser, email, error, isSignUp, password, performSignIn]
  );

  return (
    <div className="Login">
      <main>
        <header>
          <h1>
            <span>Barnaby</span>
            <Logo />
            <div className="PrereleaseBadge">Alpha</div>
          </h1>
          <h3>Lightning-fast invoicing &amp; tracking for freelancers.</h3>
          <p>
            Barnaby is a newborn app, an alpha. So it's still a bit rough—but if you're willing to brave that,{" "}
            <a href="https://dfeldman.co/contact">I'd love your feedback!</a>
          </p>
        </header>
        <AnimatePresence>
          {authState === "Unconfirmed" ? (
            <div className="LoginBox Card AwaitingVerify">
              <h3>Check Your Email</h3>
              <p>
                We've sent you an email to verify your address. Click the link there to complete your Barnaby sign-up
                and get started!
              </p>
              <p>The email should appear within seconds; and as always, don't forget to check your spam folder.</p>
            </div>
          ) : (
            <Tabs.Root value={tab} onValueChange={setTab} className="LoginBox Card" asChild>
              <motion.div layout>
                <Tabs.List className="TabsList" asChild>
                  <motion.div layout>
                    <Tabs.Trigger className="TabsTrigger" value="sign-up">
                      Create Account
                    </Tabs.Trigger>
                    <Tabs.Trigger className="TabsTrigger" value="sign-in">
                      Sign In
                    </Tabs.Trigger>
                  </motion.div>
                </Tabs.List>
                <Tabs.Content className="TabsContent" value={tab}>
                  <motion.form layout className="Login" onSubmit={handleSubmit}>
                    <Field
                      autofocus
                      type="email"
                      value={email}
                      label="Email"
                      onChange={changeHandler(setEmail)}
                      onBlur={handleBlur}
                    />
                    <Field
                      type="password"
                      value={password}
                      label={isSignUp ? "Create Password" : "Password"}
                      onChange={changeHandler(setPassword)}
                      onBlur={handleBlur}
                    />
                    {isSignUp && (
                      <Field
                        type="password"
                        value={passConfirm}
                        label="Confirm Password"
                        onChange={changeHandler(setPassConfirm)}
                        onBlur={handleBlur}
                      />
                    )}

                    {error && canShowError && <div className="Error">{error}</div>}

                    <div className="Buttons">
                      <Button
                        disabled={!(password && email && (!isSignUp || passConfirm === password))}
                        type="Primary"
                        role="submit"
                        label={tab === "sign-up" ? "Create Account" : "Sign In"}
                      />
                    </div>
                  </motion.form>
                </Tabs.Content>
              </motion.div>
            </Tabs.Root>
          )}
        </AnimatePresence>
      </main>
      <Footer />
    </div>
  );
};

export default Login;

import {useCallback, useMemo, useRef} from "react";
import Button from "../Button/Button";
import InvoiceRow from "../InvoiceRow/InvoiceRow";
import {useDataContext} from "../model/DataContextProvider";
import "./InvoiceList.scss";
import {Helmet} from "react-helmet";
import {useLogicContext} from "../model/LogicContextProvider";
import {Invoice} from "../model/Model";
import {TableRowChangeHandler, TableRowDeleteHandler} from "../Table/useTableRow";
import EditTable from "../Table/EditTable";
import {ReactComponent as ZeroImage} from "../images/zero-invoices.svg";
import Zero from "../Zero/Zero";
import {Link} from "react-router-dom";

const InvoiceList: React.FC = () => {
  const {invoices: _invoices, createInvoice, updateInvoice, deleteInvoice, clients, activities} = useDataContext();
  const {defaultClientId} = useLogicContext();
  const justAdded = useRef<string | null>(null);

  const addInvoice = useCallback(() => {
    justAdded.current = createInvoice({clientId: defaultClientId});
  }, [createInvoice, defaultClientId]);

  const handleChange: TableRowChangeHandler<Invoice> = useCallback(
    (id, updates) => {
      if (!_invoices) throw new Error("Attempted to save an invoice when invoices is not set.");
      if (!id) throw new Error("Attempted to save an invoice but missing its ID.");
      updateInvoice(id, updates);
    },
    [_invoices, updateInvoice]
  );

  const handleDelete: TableRowDeleteHandler = useCallback(
    id => {
      if (!_invoices) throw new Error("Attempted to save an invoice when invoices is not set.");
      deleteInvoice(id);
    },
    [deleteInvoice, _invoices]
  );

  const invoices = useMemo(() => (_invoices ? [..._invoices].reverse() : []), [_invoices]);

  const [zeroContent, disabledAddTooltip] = useMemo(() => {
    if (invoices.length) return ["", undefined];
    else if (clients?.length && activities?.length) {
      return [
        <>
          Barnaby automates your invoices: when you create a new invoice for a client, we automatically bundle all
          un-invoiced activities up to the date you specify. <strong>Click Add above and give it a try!</strong>
        </>,
        undefined,
      ];
    } else if (clients?.length) {
      return [
        <>
          Barnaby automatically builds invoices based on your Activities. To get started,{" "}
          <strong>
            <Link to="/activities">head over to the Activities screen!</Link>
          </strong>
        </>,
        "Before creating your first invoice, visit Activities to start tracking your work.",
      ];
    } else {
      return [
        <>
          Barnaby builds invoices using your stored client information. To get started,{" "}
          <strong>
            <Link to="/clients">head over to Clients</Link>
          </strong>{" "}
          and add your first client.
        </>,
        "Barnaby builds invoices using clients and activities. Before creating an invoice, visit those screens and add your information.",
      ];
    }
  }, [activities?.length, clients?.length, invoices.length]);

  console.log(disabledAddTooltip);

  return (
    <div className="InvoiceList Screen">
      <Helmet>
        <title>Invoices | Barnaby</title>
      </Helmet>
      <header>
        <h1>Invoices</h1>
        <Button
          disabled={Boolean(disabledAddTooltip)}
          tooltip={disabledAddTooltip}
          onClick={addInvoice}
          label="Add Invoice"
        />
      </header>
      {invoices.length ? (
        <EditTable
          hasButtons
          cols={["Date", "ID", "Client", "Items", "Amount", "Status"]}
          rows={invoices?.map(inv => (
            <InvoiceRow
              key={inv.id}
              datum={inv}
              onChange={handleChange}
              onDelete={handleDelete}
              focusOnMount={inv.id === justAdded.current}
            />
          ))}
        />
      ) : (
        <Zero image={<ZeroImage />}>{zeroContent}</Zero>
      )}
    </div>
  );
};

export default InvoiceList;

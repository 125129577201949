import {FormEventHandler, useCallback, useMemo} from "react";
import Field from "../Field/Field";
import {Client} from "../model/Model";
import {motion} from "framer-motion";
import Row from "../Field/Row";

import "./ClientView.scss";
import Select from "../Select/Select";
import useTableRow, {TableRowProps} from "../Table/useTableRow";

const ClientView: React.FC<TableRowProps<Client>> = ({datum, onChange, onDelete, focusOnMount}) => {
  const {current, elementProps, handleChange, handleImmediateChange, handleBlur, handleFocus, deleteButton} =
    useTableRow(datum, onChange, onDelete, "ClientView SimpleForm Sectioned Card");

  const periodSelectValue = useMemo(
    () => ({label: current.period || "Week", value: current.period || "Week"}),
    [current.period]
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(e => {
    e.preventDefault();
    return false;
  }, []);

  return (
    <motion.form className="ClientView SimpleForm Sectioned Card" onSubmit={handleSubmit} {...elementProps}>
      <section>
        <Row>
          <Field
            label="Enter client name"
            className="Title"
            value={current.name || ""}
            onChange={val => handleChange("name", val)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autofocus={focusOnMount}
            inlineLabel
          />
          {deleteButton}
        </Row>
      </section>
      <section>
        <Field
          label="Address"
          value={current.address || ""}
          onChange={val => handleChange("address", val)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          lines={2}
        />
        <Field
          label="Email"
          value={current.email || ""}
          onFocus={handleFocus}
          onChange={val => handleChange("email", val)}
          onBlur={handleBlur}
        />
      </section>
      <section>
        <Row align="bottom">
          <Field
            label="Hourly Rate"
            value={String(current.hourlyRate || "")}
            onChange={val => handleChange("hourlyRate", val)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            prefix="$"
          />
          <Field
            label="Period Rate"
            value={String(current.periodRate || "")}
            onChange={val => handleChange("periodRate", val)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            prefix="$"
          />
          <Select
            options={[
              {label: "Week", value: "Week"},
              {label: "Month", value: "Month"},
            ]}
            value={periodSelectValue}
            onChange={val => handleImmediateChange("period", val || "")}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Row>
        <Row>
          <Field
            label="Allocation"
            value={String(current.percentAllocation || "")}
            onChange={val => handleChange("percentAllocation", val)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            hint="Bill for % of total time period."
            suffix="%"
          />
          <Field
            label="Overhead"
            value={String(current.percentOverhead || "")}
            onChange={val => handleChange("percentOverhead", val)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            hint="Manage the ineffable. Overhead adds a % to all your timed work to cover that hard-to-track stuff."
            suffix="%"
          />
        </Row>
      </section>
    </motion.form>
  );
};

export default ClientView;

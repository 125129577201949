import {createContext, ReactNode, useCallback, useContext, useMemo} from "react";
import {
  Activity,
  Client,
  Invoice,
  LocalActivityBase,
  LocalClientBase,
  LocalInvoiceBase,
  ModelCreator,
  ModelDeleter,
  ModelUpdater,
  Period,
  UnsavedModel,
} from "./Model";
import {useUserContext} from "./UserContextProvider";
import useDbCollection from "./useDbCollection";
import {dateToStr, strToDate} from "../Util";
import {endOfMonth, endOfWeek} from "date-fns";

export const adjustActivityDate = (activity: Activity | UnsavedModel<Activity>, period: Period, dateStr: string) => {
  const date = strToDate(dateStr);
  if (activity.type === "WeekHours" || (activity.type === "Period" && period === "Week")) {
    return dateToStr(endOfWeek(date, {weekStartsOn: 6}));
  }
  if (activity.type === "Period") return dateToStr(endOfMonth(date));
  else return dateToStr(date);
};

export interface DataContextValues {
  readonly activities: Activity[] | null;
  readonly activityMap: Record<string, Activity> | null;
  createActivity: ModelCreator<Activity>;
  updateActivity: ModelUpdater<Activity>;
  deleteActivity: ModelDeleter;

  readonly clients: Client[] | null;
  readonly invoiceMap: Record<string, Invoice> | null;
  createClient: ModelCreator<Client>;
  updateClient: ModelUpdater<Client>;
  deleteClient: ModelDeleter;

  readonly invoices: Invoice[] | null;
  readonly clientMap: Record<string, Client> | null;
  createInvoice: ModelCreator<Invoice>;
  updateInvoice: ModelUpdater<Invoice>;
  deleteInvoice: ModelDeleter;

  readonly invoiceActivities: Record<string, Activity[]> | null;
}

export const DataContext = createContext<DataContextValues | null>(null);

export const useDataContext = () => {
  const values = useContext(DataContext);
  if (!values) throw new Error("Attempted to use ModelContext values outside a context.");
  return values;
};

const DataContextProvider: React.FC<{children: ReactNode}> = ({children}) => {
  const {getPref, setPref, authState} = useUserContext();
  const signedIn = authState === "SignedIn";
  const dbA = useDbCollection<Activity>("activities", LocalActivityBase, signedIn);
  const dbC = useDbCollection<Client>("clients", LocalClientBase, signedIn);
  const dbI = useDbCollection<Invoice>("invoices", LocalInvoiceBase, signedIn);

  // TODO-DB is there some sort of index thing we can use here?
  const invoiceActivities = useMemo(() => {
    if (!dbA.all || !dbA.map || !dbC.all || !dbC.map || !dbI.all || !dbI.map) return null;

    const result: Record<string, Activity[]> = {};
    for (let i = 0; i < dbA.all.length; i++) {
      const act = dbA.all[i];
      if (act.invoiceId) {
        if (!dbI.map[act.invoiceId]) {
          throw new Error(`Activity ${act.id} references nonexistent invoice ${act.invoiceId}.`);
        }
        if (dbI.map[act.invoiceId].status === "Draft") {
          // TODO-DB This can be an error case again once Supabase supports transactions or we move some logic server-side.
          // throw new Error(`Activity ${act.id} references Draft invoice ${act.invoiceId}`);
          continue;
        }

        if (!result[act.invoiceId]) result[act.invoiceId] = [];
        result[act.invoiceId].push(dbA.map[act.id]);
      }
    }

    // TODO-DB performance? Worth caching this on each update? Alternatives?
    const activitiesAssignedToInvoices: Record<string, boolean> = {};
    dbI.all.forEach(inv => {
      if (inv.status === "Draft") {
        if (result[inv.id]) throw new Error(`Draft invoice ${inv.id} somehow has assigned activitites.`);
        result[inv.id] = [];
        dbA.all?.forEach(act => {
          if (
            !act.invoiceId && // Activity isn't pinned to a sent/paid invoice
            !activitiesAssignedToInvoices[act.id] && // We haven't assigned this activity to a draft invoice
            act.clientId && // Activity has a client...
            inv.clientId === act.clientId && // ...that matches this invoice
            act.date <= inv.date // Activity is dated on or before invoice date
          ) {
            activitiesAssignedToInvoices[act.id] = true;
            result[inv.id].push(act);
          }
        });
      }
    });

    return result;
  }, [dbA.all, dbA.map, dbC.all, dbC.map, dbI.all, dbI.map]);

  const createActivity: ModelCreator<Activity> = act => {
    if (dbC.map && act.clientId && act.date) {
      act.date = adjustActivityDate(act, dbC.map[act.clientId].period as Period, act.date); // TODO-TS
    }
    if (!act.clientId) act.clientId = null;
    return dbA.create(act);
  };

  const updateActivity: ModelUpdater<Activity> = (id, upd) => {
    if (upd.type) {
      // Adjust date if needed based on new activity type
      if (!dbA.map) throw new Error("Tried to update an activity before we had an activity map.");
      const act = dbA.map[id];
      const adjDate =
        dbC.map && act.clientId
          ? adjustActivityDate({...act, type: upd.type}, dbC.map[act.clientId].period as Period, act.date) // TODO-TS
          : act.date;
      if (adjDate !== act.date) upd.date = adjDate;

      // Update client's default activity type if needed
      if (act.clientId) dbC.update(act.clientId, {defaultActivityType: upd.type});
    }
    dbA.update(id, upd);
  };

  const deleteActivity: ModelDeleter = (id: string) => {
    const act = dbA.all?.find(act => act.id === id);
    if (!act) throw new Error("Tried to delete nonexistent activity.");
    if (act.invoiceId) throw new Error("Can't delete an activity that's been pinned to an invoice.");
    dbA.del(id);
  };

  const createInvoice: ModelCreator<Invoice> = useCallback(
    invoice => {
      const index = getPref("nextInvoiceNumber") || 1;
      if (typeof index !== "number") throw new Error("Got a non-numeric nextInvoiceNumber.");
      if (invoice.status && invoice.status !== "Draft") {
        throw new Error("TODO: Add business logic to create an invoice that is not a draft.");
      }
      if (!invoice.clientId) invoice.clientId = null;

      // TODO: increment
      setPref("nextInvoiceNumber", index + 1);

      return dbI.create({...invoice, index});
    },
    [dbI, getPref, setPref]
  );

  const updateInvoice: ModelUpdater<Invoice> = useCallback(
    (id, updates) => {
      if (!dbI.map || !invoiceActivities) throw new Error("Tried to update an invoice before we had maps.");

      // TODO transaction / batch?
      if (updates.status !== undefined && updates.status !== dbI.map[id].status) {
        // Invoice status has changed.

        // Unbind or bind any activities for this invoice
        invoiceActivities[id]?.forEach(act => dbA.update(act.id, {invoiceId: updates.status === "Draft" ? null : id}));

        // Client snapshots: once sent, an invoice needs to capture the state of the client at the point it was sent,
        // so subsequent updates to rates, overhead, etc. don't affect it.
        if (updates.status === "Draft") {
          // Sent/Paid invoice turned back into Draft. Remove snapshot.
          // TODO This might benefit from a user-facing confirmation.
          updates.clientSnapshot = null;
        } else if (dbI.map[id].status === "Draft") {
          // Capture new client snapshot if and only if we're converting FROM a Draft (vs flipping between Sent & Paid).
          const clientId = dbI.map[id].clientId;
          if (!clientId) throw new Error(`Tried to set a non-draft status on invoice ${id}, which lacks a clientId.`);
          if (!dbC.map || !dbC.map[clientId]) {
            throw new Error(
              `While setting status of invoice ${id}, found client ID ${clientId} that doesn't correspond to anything in the client map.`
            );
          }
          updates.clientSnapshot = dbC.map[clientId];
        }
      }
      dbI.update(id, updates);
    },
    [dbA, dbC.map, dbI, invoiceActivities]
  );

  const updateClient: ModelUpdater<Client> = useCallback(
    (id, updates) => {
      dbC.update(id, updates);
      if (getPref("lastClientSelected") !== id) setPref("lastClientSelected", id);
    },
    [dbC, getPref, setPref]
  );

  const resultValues: DataContextValues = {
    activities: dbA.all,
    activityMap: dbA.map,
    createActivity,
    updateActivity,
    deleteActivity,

    invoices: dbI.all,
    invoiceMap: dbI.map,
    createInvoice,
    updateInvoice,
    deleteInvoice: dbI.del, // Supabase/Postgres should take care of nulling any associated activity references

    clients: dbC.all,
    clientMap: dbC.map,
    createClient: dbC.create,
    updateClient,
    deleteClient: dbC.del,

    invoiceActivities,
  };

  return <DataContext.Provider value={resultValues}>{children}</DataContext.Provider>;
};

export default DataContextProvider;

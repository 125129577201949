import {Link, useLocation} from "react-router-dom";
import {useUserContext} from "../model/UserContextProvider";
import classNames from "classnames";
import Button from "../Button/Button";
import Spacer from "../Spacer";
import "./Nav.scss";
import {ActivityIcon, ClientIcon, FeedbackIcon, InvoiceIcon, ProfileIcon, SignOutIcon} from "../Icons";
import {ReactComponent as Logo} from "../images/logo.svg";

const NavItems: [string, string, JSX.Element][] = [
  ["activities", "Activities", ActivityIcon],
  ["invoices", "Invoices", InvoiceIcon],
  ["clients", "Clients", ClientIcon],
  ["profile", "Profile", ProfileIcon],
];

const Nav: React.FC = () => {
  const {performSignOut, authState} = useUserContext();
  const loc = useLocation();

  return (
    <nav className="Nav">
      <h1>
        <span>Barnaby</span>
        <Logo />
      </h1>
      {NavItems.map(([slug, label, icon]) => (
        <Link
          className={classNames("NavItem", {Current: loc.pathname.startsWith(`/${slug}`)})}
          key={slug}
          to={`/${slug}`}
        >
          {icon}
          {label}
        </Link>
      ))}
      <Spacer />
      <div className="BottomBar">
        <Button
          className="SignOutButton NavItem"
          icon={SignOutIcon}
          type="Borderless"
          disabled={authState !== "SignedIn" && authState !== "Unconfirmed"}
          onClick={performSignOut}
          tooltip="Sign out of Barnaby"
        />
        <Button
          className="NavItem"
          icon={FeedbackIcon}
          type="Borderless"
          onClick={() => {
            window.location.href = "mailto:feedback@barnaby.app";
          }}
          tooltip="Send us feedback (feedback@barnaby.app)"
        />
      </div>
    </nav>
  );
};

export default Nav;

import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import UserContextProvider from "./model/UserContextProvider";
import DataContextProvider from "./model/DataContextProvider";
import LogicContextProvider from "./model/LogicContextProvider";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <BrowserRouter>
    <UserContextProvider>
      <DataContextProvider>
        <LogicContextProvider>
          <App />
        </LogicContextProvider>
      </DataContextProvider>
    </UserContextProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {ReactNode} from "react";
import "./Row.scss";
import classNames from "classnames";

type VAlign = "top" | "center" | "bottom";

interface RowProps {
  label?: string;
  children: ReactNode;
  align?: VAlign;
}

const AlignClasses: Record<VAlign, string> = {
  top: "AlignTop",
  center: "AlignCenter",
  bottom: "AlignBottom",
};

const Row: React.FC<RowProps> = ({children, label, align}) => {
  return label ? (
    <div className={classNames("Row", align && AlignClasses[align])}>
      <label>{label}</label>
      <div className="RowContent">{children}</div>
    </div>
  ) : (
    <div className={classNames("Row", "RowContent", align && AlignClasses[align])}>{children}</div>
  );
};

export default Row;

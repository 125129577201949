import {Invoice} from "../model/Model";
import "./InvoiceRow.scss";
import {formatAsCurrency} from "../Util";
import useComputedInvoiceFields from "../model/useComputedInvoiceFields";
import useTableRow from "../Table/useTableRow";
import {useDataContext} from "../model/DataContextProvider";

const CsvInvoiceRow: React.FC<{datum: Invoice}> = ({datum}) => {
  const {clientMap} = useDataContext();
  const {total, invoiceNumber} = useComputedInvoiceFields(datum);
  const {current} = useTableRow(
    datum,
    () => {},
    () => {}
  );

  return (
    <>
      "{current.date}", "{invoiceNumber}", "{current.clientId && clientMap ? clientMap[current.clientId].name : ""}", "
      {formatAsCurrency(total)}", "{current.status}", "{`https://barnaby.app/invoices/${datum.id}`}"<br />
    </>
  );
};

export default CsvInvoiceRow;

import {ReactNode, useCallback, useEffect, useMemo, useState} from "react";
import ClientInput from "../ClientInput/ClientInput";
import DateField from "../DateField/DateField";
import Input from "../Input/Input";
import {
  Activity,
  ActivityTypeOptionArray,
  DefaultHoursSummary,
  DefaultPricedSummary,
  EmptyWeekAmounts,
} from "../model/Model";
import {HourLabel, dateToStr, strToDate} from "../Util";
import WeekInputs from "./WeekInputs";
import {useLogicContext} from "../model/LogicContextProvider";
import {adjustActivityDate} from "../model/DataContextProvider";
import useTableRow, {TableRowProps} from "../Table/useTableRow";
import Cell from "../Table/Cell";
import {motion} from "framer-motion";

const ActivityRow: React.FC<TableRowProps<Activity>> = ({datum, onChange, onDelete, focusOnMount}) => {
  const {getUnadjustedQtyByType, getComputedSummary, getClientForActivity} = useLogicContext();
  const {
    current,
    handleChange,
    handleImmediateChange,
    makeInput,
    handleFocus,
    handleBlur,
    makeSelect,
    deleteButton,
    elementProps,
  } = useTableRow(datum, onChange, onDelete, "ActivityRow");

  const [date, setDate] = useState(current.date); // ...because some date "changes" won't result in an activity change
  useEffect(() => setDate(current.date), [current.date]);

  const handleDateChange = useCallback(
    (val: Date | null, committingChange: boolean) => {
      if (!val) handleChange("date", null);
      else {
        const adjDate = adjustActivityDate(current, getClientForActivity(current)?.period || "Week", dateToStr(val));
        setDate(adjDate);
        (committingChange ? handleImmediateChange : handleChange)("date", adjDate);
      }
    },
    [current, getClientForActivity, handleChange, handleImmediateChange]
  );

  const activityTypeOptions = useMemo(() => {
    const result = ActivityTypeOptionArray.map(item => ({...item})); // Second-level copy
    const idx = result.findIndex(item => item.value === "Period");
    result[idx].label = getClientForActivity(current)?.period === "Month" ? "Monthly" : "Weekly";
    return result;
  }, [current, getClientForActivity]);

  const [details, total] = useMemo(() => {
    let _details, _total: ReactNode;
    const {type, summary} = current;

    switch (type) {
      case "Hours":
      case "Priced":
        _details = makeInput(
          "summary",
          "text",
          summary || "",
          type === "Hours" ? DefaultHoursSummary : DefaultPricedSummary
        );
        _total = (
          <span>
            <Input
              type="number"
              value={getUnadjustedQtyByType(current)}
              onChange={newVal => handleChange("amount", newVal)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              prefix={type === "Priced" ? "$" : undefined}
            />
            {type === "Hours" && <span className="Unit">{HourLabel}</span>}
          </span>
        );

        break;

      case "WeekHours":
        _total = (
          <>
            <span>{getUnadjustedQtyByType(current)}</span>
            <span className="Unit">{HourLabel}</span>
          </>
        );
        _details = (
          <WeekInputs
            value={current.weeklyHoursByDay || {...EmptyWeekAmounts}}
            onChange={newVal => handleChange("weeklyHoursByDay", newVal)}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        );
        break;

      case "Period":
        _total = <span>{getUnadjustedQtyByType(current)}</span>;
        _details = <span>{getComputedSummary(current)}</span>;
        break;
    }

    return [_details, _total];
  }, [current, getComputedSummary, getUnadjustedQtyByType, handleBlur, handleChange, handleFocus, makeInput]);

  return (
    <motion.tr {...elementProps}>
      <Cell>
        <DateField
          dateFormat="ShortDateWithDay"
          value={strToDate(date)}
          onChange={handleDateChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          focusOnMount={focusOnMount}
        />
      </Cell>
      <Cell>
        <ClientInput
          selectedClientId={current.clientId || undefined}
          onChange={id => handleImmediateChange("clientId", id)}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </Cell>
      <Cell className="Type">{makeSelect("type", current.type, activityTypeOptions)}</Cell>
      <Cell className="Details">{details}</Cell>
      <Cell className="Total">{total}</Cell>
      <Cell className="Btn">{deleteButton}</Cell>
    </motion.tr>
  );
};

export default ActivityRow;
